import React from "react";

/**
 * Show hosts component
 * @param {array} hosts Contains an object for each host
 */
export default function ShowHosts({hosts}) {
  return (
    <section className="showHosts">
      <h2 className="show__heading">Show Hosts</h2>
      <dl>
      {
        hosts.map((host, index) => {
          return (
            <React.Fragment key={index}>
              <dt className="host__item">{host.name}</dt>
              { 
                host.twitter && 
                <dd><b>twitter:</b> <a href={`https://twitter.com/${host.twitter}`}>@{host.twitter}</a></dd>
              }
              { 
                host.site && 
                <dd><b>site:</b> <a href={host.site}>{host.site}</a></dd>
              }
              { 
                host.github && 
                <dd><b>github:</b> <a href={host.github}>{host.github}</a></dd>
              }
            </React.Fragment>
          )
        })
      }
      </dl>
    </section>
  )
}
