import React from 'react';

export default function ListenOn({ feedUrl, listenOn }) {
  return (
    <>
      <h2 className="show__heading">Subscribe</h2>
      <ul className="listen">
        {listenOn.length > 0 &&
          listenOn.map((listen, index) => {
            return (
              <li key={index} className="listen__item">
                <a href={listen.url}>{listen.where}</a>
              </li>
            );
          })}
        <li key="0rssfeed" className="listen__item">
          <a href={feedUrl}>RSS</a>
        </li>
      </ul>
    </>
  );
}
