import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import EpisodesList from '../components/episodes-list';
import Disclaimer from '../components/Disclaimer';
import ListenOn from '../components/listen-on';
import ShowHosts from '../components/show-hosts';
import SEO from '../components/seo/seo';

/**
 * Show Landing Page
 * @param {object} data Show and show episodes data from GraphQL
 */
export default function ShowLandingPageVariationTemplate({ data }) {
  const show = data.showsMetaJson;
  const episodes = data.allMarkdownRemark; // data.allMarkdownRemark.edges;

  // <pre>
  //   { JSON.stringify(data, null, 2) }
  // </pre>

  /**
   * Create show's description that displays above the list of episodes
   * @param {string} description Show description lain text or text mixed with HTML
   * @returns Description text formatted with or without HTML as appropriate
   */
  function renderShowDescription(description) {
    if (!description.includes('<p>')) {
      return `<p class="show__description">${description}</p>`;
    } else {
      return description;
    }
  }

  return (
    <>
      <SEO title={`${show.title}`} description={show.description} />
      <section className="show">
        {/* ==== START SHOW INTRO ==== */}
        <div className="showIntro">
          <Img
            fluid={show.logo.childImageSharp.fluid}
            alt={show.title}
            className="showIntro__logo"
          />

          <div className="showIntro__inner">
            <h1 className="show__title">{show.title}</h1>
            <a href="#episodes" className="skiplink show__skiplink">
              Skip to Episodes
            </a>

            {show.description && (
              <div
                dangerouslySetInnerHTML={{
                  __html: renderShowDescription(show.description),
                }}
                className="show__description"
              />
            )}
          </div>
        </div>

        {/* ==== START SHOW DETAILS ==== */}
        <aside className="showDetails">
          {/* Hosts */}
          {show.hosts.length > 0 && (
            <>
              <ShowHosts hosts={show.hosts} />
              <hr />
            </>
          )}

          {/* Listen On */}
          <ListenOn feedUrl={show.feedUrl} listenOn={show.listenOn} />
          <hr />

          {/* Visit site + Legal */}
          <p>
            Visit the {''}
            <a href={show.link} rel="external">
              show’s official site
            </a>
            .
          </p>
          <hr />
          <Disclaimer showTitle={show.title} showType="podcast" />
        </aside>

        {/* ==== START SHOW EPISODES ==== */}
        <div className="showEpisodes">
          <h2
            id="episodes"
            tabIndex="-1"
            className="show__heading skiplinkTarget"
          >
            Episodes ({episodes.edges.length})
          </h2>

          <EpisodesList
            episodes={episodes}
            showSummary={false}
            showsMeta={show}
            classChildren="episodes__item--btn-info"
          />
        </div>
      </section>
    </>
  );
}

/*
What to show on this page:
  x - show logo image
  - show name (maybe)
  - link: official website
  - links: where else to listen, i.e., apple, spotify, etc.

  latest episode

  maybe
  - show description [this varies a lot per show. some short, some long.]
  - names of hosts
  - when started
  - days they publish
  - links to twitter, etc.
*/

export const showPageQuery = graphql`
  query showVariationQuery($showId: String!) {
    allMarkdownRemark(
      filter: { frontmatter: { showId: { eq: $showId } } }
      sort: { order: DESC, fields: frontmatter___pubDate }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            showId
            pubDate(formatString: "MMMM D, YYYY")
            summary
            url
          }
        }
      }
    }

    showsMetaJson(id: { eq: $showId }) {
      id
      title
      description
      hosts {
        name
        site
        twitter
        github
      }
      feedUrl
      link
      listenOn {
        where
        url
      }
      socialMedia {
        handle
        url
        site
      }
      ...Logo
    }
  }
`;

/* ==== START LATEST SHOW   ==== */
/*
<div className="showLatest">
  <Img fluid={show.logo.childImageSharp.fluid} alt={show.title}  className="showLatest__logo show__img" />

  <div className="showLatest__inner">
    <h2>Latest Episode</h2>

    <p className="showLatest__info episodes__info">
      <time dateTime="{ep.pubDate}" className="episodes__date">{ep.pubDate}</time>

      <Link 
      to={`/podcasts/${ep.showId}/${utils.createEpisodeFoldername(ep.title)}`} className="episodes__link">
      {ep.title}</Link>
    </p>

    { summary && summary }

    <BtnPlay showTitle={show.title} episodeTitle={ep.title} episodeUrl={ep.url} customClasses='showLatest__btn' />
  </div>
</div>
*/

// <small>
// The <cite>{show.title}</cite> logo, text content, and audio
// content is all property of the show.
// </small>
